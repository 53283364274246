/* paginationComponentStyle.scss */

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination-select {
  padding: 8px 12px;
  margin-right: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f2f2f2;
  }
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f2f2f2;
  }

  &:disabled {
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
  }
  
  & + .pagination-button {
    margin-left: 10px;
  }
  
}
