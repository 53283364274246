.header{
    background-color: #185294;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 5px;
    margin-bottom: 10px;
}
.header p{
    margin: 0;
    align-self: center;
    justify-self: start;
}