.footer {
  background-color: #185294;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.footer p {
  margin: 0;
  align-self: center;
  justify-self: start;
}