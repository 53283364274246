/* tableComponentStyle.scss */

.main-component {
  width: 50%;
  margin: auto;
  overflow: hidden;
  margin-bottom: 50px;
}

.table-header{
  background-color: #D3D3D3;
}

.sticky-table{
  margin: auto;
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #D3D3D3;
  border-radius: 5px;
}

.sticky-table tr:nth-child(even) {
  background-color: #D3D3D3;
}

.table-data{
  border: 1px solid #D3D3D3;
}

