.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: static;
    margin: 15px;
}
.form-container form {
    display: flex;
    flex-direction: column;
    width: 400px;
}
.input-group {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: space-between; 
    margin-bottom: 15px;
    margin-right: 15px;
}


.input-group input {
    padding: 2px;
    border-radius: 5px;
    width: 300px;
    
}
.button{
    display: flex;

}

.error-message {
    color: red;
    margin-left: 10px;
}